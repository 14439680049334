@import url('https://fonts.googleapis.com/css2?family=Wittgenstein:ital,wght@0,400..900;1,400..900&display=swap');
:root {
	/* Colors */
	--brown: hsl(10, 56%, 51%);
	--orange: hsl(28, 88%, 59%);
	--peach: hsl(0, 0%, 100%);
	--green: hsl(180, 22%, 45%);
	
	/* Gradients */
	--lg-peach: linear-gradient(45deg, var(--white)0%, var(--peach)100%);
}
* {
	margin: 0;
	padding: 0;
	box-sizing: content-box;
}
body {
	background: var(--lg-peach);
	font-family: 'Wittgenstein', serif;
	font-size: 16px;
}

article {
	margin: 0 auto;
	width: 80vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
img {
	width: 70%;
}
h1 {
	margin-bottom: 1rem;
}
